import { useNavigate } from 'react-router-dom';

import useMount from 'hooks/useMount';
import { updateNativeNotificationsToken } from 'api/requests/notifications';
import { CashierNotificationType } from 'types/notifications';
import useNotifications from 'store/notifications';

import sendMessage from '../common/sendMessage';
import { IMessageHandler } from '../common/types';
import addMessageHandler from '../common/listener';

import { INativeNotificationsInfo, ReceiveNativeNotificationsMessages, SendNativeNotificationsMessages } from './types';

const useNativeNotifications = (): INativeNotificationsInfo => {
  const enableNotifications = (): void => {
    sendMessage({ type: SendNativeNotificationsMessages.enableNotifications });
  };
  const navigate = useNavigate();
  const [, notificationActions] = useNotifications();

  useMount(() => {
    const listener: IMessageHandler<ReceiveNativeNotificationsMessages> = async ({ type, payload }) => {
      switch (type) {
        case ReceiveNativeNotificationsMessages.enableNotifications: {
          updateNativeNotificationsToken(payload.token as string);
          break;
        }
        case ReceiveNativeNotificationsMessages.nativeNotification: {
          const notification = payload as {
            /**
             * Notification title - the bold text displayed above the rest of the content.
             */
            title: string | null;
            /**
             * On Android: `subText` - the display depends on the device.
             *
             * On iOS: `subtitle` - the bold text displayed between title and the rest of the content.
             */
            subtitle: string | null;
            /**
             * Notification body - the main content of the notification.
             */
            body: string | null;
            /**
             * Data associated with the notification, not displayed
             */
            data: Record<string, unknown>;
            // @docsMissing
            sound: 'default' | 'defaultCritical' | 'custom' | null;
          };

          const backendLikeNotification = {
            seenAt: null,
            createdAt: Date.now(),
            name: notification.title || '',
            content: notification.body || '',
            subject: notification.subtitle || '',
            id: Number(notification.data.id),
            actionId: notification.data.actionId as CashierNotificationType,
          };
          notificationActions.addNotification(backendLikeNotification);
          if (backendLikeNotification.actionId === CashierNotificationType.DEPOSIT_REQUEST) {
            setTimeout(() => {
              navigate('/payments/deposits/in-review');
            }, 200);
          } else if (backendLikeNotification.actionId === CashierNotificationType.WITHDRAW_REQUEST) {
            setTimeout(() => {
              navigate('/payments/withdraws/in-review');
            }, 200);
          }
          notificationActions.updateNotificationById(backendLikeNotification.id);
          break;
        }
        default:
          break;
      }
    };

    return addMessageHandler(listener);
  });

  return { enableNotifications };
};

export default useNativeNotifications;
