export { default as HttpStatusCode } from './HttpStatusCode';

export * from './static';

export const BASE_API = {
  URL: process.env.REACT_APP_BASE_API_URL,
  TOKEN_TYPE: 'Bearer',
};

export const SOCKET_BASE_URL = process.env.REACT_APP_WS_URL;

export const IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL;

export const DEFAULT_TITLE = '';
export const TABLE_SIZES = [10, 20, 50, 100];
export const DEFAULT_TABLE_SIZE = 50;

export const ACCEPT_IMAGES_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

export const TIMEZONE_DIFFERENCE = 8;
export const DATE_FORMAT = 'DD.MM.YYYY';
export const BROWSER_DATE_FORMAT = 'YYYY-MM-DD';
export const BROWSER_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const FULL_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
