import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiResponseType, PaginatedResponse } from 'types';
import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { EMPTY_ARRAY } from 'constant';
import { ICashierNotification } from 'types/notifications';
import {
  getNotificationsRequest,
  getUnreadNotificationsCountRequest,
  updateNotificationById,
} from 'api/requests/notifications';

type NotificationsStateType = {
  data: ICashierNotification[];
  count: number;
  unreadCount: number;
  isLoading: boolean;
};

type NotificationsActionsType = {
  get: () => void;
  getUnreadCount: () => void;
  updateNotificationById: (notificationId: number) => void;
  addNotification: (notification: ICashierNotification) => void;
};

const initialState: NotificationsStateType = {
  isLoading: false,
  count: 0,
  unreadCount: 0,
  data: EMPTY_ARRAY,
};

const getNotifications = createAsyncThunk<ApiResponseType<ICashierNotification[]>>(
  'notifications/get',
  getNotificationsRequest,
);

const getUnreadNotificationsCount = createAsyncThunk<ApiResponseType<number>>(
  'notifications/getUnreadCount',
  getUnreadNotificationsCountRequest,
);

const updateNotification = createAsyncThunk<ApiResponseType<ICashierNotification>, number>(
  'notifications/updateById',
  updateNotificationById,
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<ICashierNotification>): void => {
      state.data = [...state.data, action.payload];
      state.unreadCount = state.unreadCount + 1;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getNotifications.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(
        getNotifications.fulfilled.type,
        (state, action: PayloadAction<{ data: PaginatedResponse<ICashierNotification> }>): void => {
          state.isLoading = false;
          state.data = action.payload.data.data;
          state.count = action.payload.data.count;
        },
      )
      .addCase(getNotifications.rejected.type, (state): void => {
        state.isLoading = false;
        state.data = EMPTY_ARRAY;
      })
      .addCase(updateNotification.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(updateNotification.fulfilled.type, (state, action: PayloadAction<ICashierNotification>): void => {
        state.isLoading = false;
        state.data = state.data.map((notification) => {
          if (notification.id === action.payload.id) {
            return action.payload;
          }
          return notification;
        });
        state.unreadCount = state.unreadCount - 1;
      })
      .addCase(updateNotification.rejected.type, (state): void => {
        state.isLoading = false;
      })
      .addCase(getUnreadNotificationsCount.fulfilled.type, (state, action: PayloadAction<{ data: number }>): void => {
        state.unreadCount = action.payload.data;
      }),
});

const useNotifications = (): [NotificationsStateType, NotificationsActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.notifications);
  const dispatch = useAppDispatch();

  const actions = {
    get: (): void => {
      dispatch(getNotifications());
    },
    getUnreadCount(): void {
      dispatch(getUnreadNotificationsCount());
    },
    updateNotificationById: (notificationId: number): void => {
      const notification = state.data.find(({ id }) => id === notificationId);
      const isAlreadyRead = !!notification?.seenAt;

      if (!isAlreadyRead) {
        dispatch(updateNotification(notificationId));
      }
    },
    addNotification: (notification: ICashierNotification): void => {
      dispatch(notificationsSlice.actions.addNotification(notification));
    },
  };

  return [state, actions];
};

export { notificationsSlice };

export default useNotifications;
