import { authSlice } from './auth';
import { brandingSlice } from './branding';
import { partnerSlice } from './partner';
import { shopSlice } from './shop';
import { userSlice } from './user';
import { allCountriesSlice } from './countries';
import { currenciesSlice } from './currencies';
import { configurationSlice } from './configuration';
import { paymentMethodSlice } from './paymentMethod';
import { notificationsSlice } from './notifications';
export const auth = authSlice.reducer;
export const branding = brandingSlice.reducer;
export const partner = partnerSlice.reducer;
export const shop = shopSlice.reducer;
export const user = userSlice.reducer;
export const allCountries = allCountriesSlice.reducer;
export const currencies = currenciesSlice.reducer;
export const configuration = configurationSlice.reducer;
export const notifications = notificationsSlice.reducer;
export const paymentMethod = paymentMethodSlice.reducer;
