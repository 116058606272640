import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import useToaster from 'hooks/useToaster';
import { TranslationKey } from 'utils/translate';
import { ICashierNotification, CashierNotificationType } from 'types/notifications';
import useNotifications from 'store/notifications';

export const useHandleNotification = (): ((params: ICashierNotification) => void) => {
  const notify = useToaster();
  const navigate = useNavigate();
  const [, notificationActions] = useNotifications();
  const handleNotification = useCallback(
    (notification: ICashierNotification): void => {
      notificationActions.addNotification(notification);
      switch (notification.actionId) {
        case CashierNotificationType.DEPOSIT_REQUEST:
          notify({
            type: 'info',
            message: notification.content as TranslationKey,
            title: notification.subject as TranslationKey,
            onClick: (): void => {
              navigate('/payments/deposits/in-review');
              notificationActions.updateNotificationById(notification.id);
            },
          });
          break;
        case CashierNotificationType.WITHDRAW_REQUEST:
          notify({
            type: 'info',
            message: notification.content as TranslationKey,
            title: notification.subject as TranslationKey,
            onClick: (): void => {
              navigate('/payments/withdraws/in-review');
              notificationActions.updateNotificationById(notification.id);
            },
          });
          break;
        default:
          break;
      }
    },
    [navigate, notify, notificationActions],
  );

  return handleNotification;
};
