import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FooterSelectMenuTypeEnum } from 'enums/entity';
import { CashierPermissionsEnum } from 'enums/accessTemplates';

import { translate } from 'utils/translate';
import useAuth from 'store/auth';
import usePartners from 'store/partner';
import { AccessChangePartnerPermissions } from 'utils/accessTemplates';
import { revokeNativeNotificationToken } from 'api/requests/notifications';

import { ProfileDrawerSelectedListType } from './types';

type Props = {
  onClose: () => void;
  onChangePassword: () => void;
  setOpenSelectList: (type: ProfileDrawerSelectedListType) => void;
  permission?: CashierPermissionsEnum;
};

const ProfileDrawerMainMenu: FC<Props> = ({ onClose, onChangePassword, setOpenSelectList, permission }) => {
  const navigate = useNavigate();
  const [authState, authAction] = useAuth();
  const [partner] = usePartners();
  const selected = authState?.data?.cashier?.activePartnerId || authState?.data?.cashier?.partner?.id;
  const selectedName = partner.data.find((item) => item.id === selected)?.name;

  const handleChangePassword = (): void => {
    onClose();
    onChangePassword();
  };

  const handleLogout = (): void => {
    revokeNativeNotificationToken();
    authAction.logout();
  };

  const handleProfile = (): void => {
    navigate(`/users/${authState?.data?.cashier?.id}/dashboard`);
    onClose();
  };

  const handleWebsiteClick = (): void => {
    setOpenSelectList(FooterSelectMenuTypeEnum.PARTNER);
  };

  return (
    <List>
      {AccessChangePartnerPermissions.some((r) => r === permission) && (
        <ListItem>
          <Button
            color="inherit"
            fullWidth
            sx={{ justifyContent: 'space-between', p: 0 }}
            onClick={handleWebsiteClick}
            endIcon={<ArrowForwardIosIcon />}
          >
            <Typography component="span" variant="body1" whiteSpace="nowrap">
              {selectedName}
            </Typography>
          </Button>
        </ListItem>
      )}
      <ListItem disablePadding>
        <ListItemButton onClick={handleProfile}>
          <ListItemText primary={translate('Profile')} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton onClick={handleChangePassword}>
          <ListItemText primary={translate('Change Password')} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton onClick={handleLogout}>
          <ListItemText primary={translate('Logout')} sx={{ color: 'custom.red.scale.500' }} />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default ProfileDrawerMainMenu;
