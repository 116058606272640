export enum ReceiveNativeNotificationsMessages {
  nativeNotification = 'nativeNotification',
  enableNotifications = 'enableNotifications',
  disableNotifications = 'disableNotifications',
}

export enum SendNativeNotificationsMessages {
  enableNotifications = 'enableNotifications',
  disableNotifications = 'disableNotifications',
}

export interface INativeNotificationsInfo {
  enableNotifications: () => void;
}
