import { IMessageHandler, IMessageHandlerParams } from './types';

declare global {
  interface Window {
    snooze: boolean;
    messageHandler: IMessageHandler;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let listeners: IMessageHandler<any, any>[] = [];

window.messageHandler = (params: IMessageHandlerParams<string, Record<string, unknown>>): void => {
  listeners.forEach((listener) => listener(params));
};

const addMessageHandler = <MessageType, Payload>(
  messageHandler: IMessageHandler<MessageType, Payload>,
): (() => void) => {
  const handler = (event: Event): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { type, payload } = JSON.parse(event.data);
    messageHandler({ type, payload });
  };

  document.addEventListener('message', handler);
  listeners.push(messageHandler);
  return () => {
    document.removeEventListener('message', handler);
    listeners = listeners.filter((listener) => listener !== messageHandler);
  };
};

export default addMessageHandler;
