import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AppLayout from 'pages/App';
import AuthLayout from 'pages/Auth';
import useAuth from 'store/auth';
import useBranding from 'store/branding';
import { DEFAULT_TITLE } from 'configs';
import { GET_CASHIERS_BRANDING, GET_CASHIERS_SETTINGS_BRANDING } from 'api/paths/constants';
import useSockets from 'hooks/useSocket.ts';

import useDidMount from '../hooks/useDidMount';

const Pages: FC = () => {
  const { connect } = useSockets();

  const { pathname } = useLocation();

  const [authState] = useAuth();
  const [state, brandingActions] = useBranding();

  useEffect(() => {
    if (!authState.isLoading) {
      if (authState?.data?.accessToken && authState?.data?.cashier?.id) {
        brandingActions.get(GET_CASHIERS_SETTINGS_BRANDING);
      } else {
        brandingActions.get(GET_CASHIERS_BRANDING);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isLoading]);

  useDidMount(() => {
    document.title = state.settings.title || DEFAULT_TITLE;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const casherId = authState?.data?.cashier?.id;

  useEffect(() => {
    if (casherId) {
      connect();
    }
  }, [casherId, connect]);

  if (authState?.data?.accessToken && authState?.data?.cashier?.id) {
    return <AppLayout />;
  }

  return <AuthLayout />;
};

export default Pages;
