import { io, Socket } from 'socket.io-client';
import { useCallback } from 'react';

import { SOCKET_BASE_URL } from 'configs';
import { authStorage } from 'store/storage';
import { USER_LOGOUT } from 'utils/logoutListener';

import { useHandleNotification } from './notification';

const _socket: {
  current: Socket | null;
  tokenExists: boolean;
} = {
  current: null,
  tokenExists: false,
};

window.addEventListener(USER_LOGOUT, () => {
  if (_socket.current) {
    _socket.current.disconnect();
  }
});

export enum SocketUserRooms {
  notification = 'notification',
}

const useSockets = (): { connect: () => void } => {
  const handleNotification = useHandleNotification();
  const connect = useCallback((): void => {
    const token = authStorage.get()?.accessToken;

    const tokenExists = !!token;
    if (!tokenExists) {
      _socket.current?.disconnect();
      _socket.current = null;
      return;
    }

    if (_socket.current) return;

    _socket.current =
      _socket.current ||
      io(SOCKET_BASE_URL, {
        transports: ['websocket'],
        query: {
          token,
          type: 'cashier',
        },
      });

    _socket.current.on(SocketUserRooms.notification, handleNotification);

    _socket.current.on('disconnect', () => {
      _socket.current = null;
      connect();
    });
    _socket.current.on('close', () => {
      _socket.current = null;
      connect();
    });
  }, [handleNotification]);

  return {
    connect,
  };
};

export default useSockets;
