import { IUserListDTO } from './user';

export enum CashierNotificationType {
  WITHDRAW_REQUEST = 'WITHDRAW_REQUEST',
  DEPOSIT_REQUEST = 'DEPOSIT_REQUEST',
}

export interface ICashierNotification {
  id: number;
  name: string;
  subject: string;
  content: string;
  createdAt: number;
  seenAt: number | null;
  cashier?: IUserListDTO;
  actionId: CashierNotificationType;
}
