import { Badge, Box, Menu, MenuItem, Tab, Tabs, Typography } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import useMount from 'hooks/useMount';
import useNotifications from 'store/notifications';
import { ICashierNotification, CashierNotificationType } from 'types/notifications';
import { translate } from 'utils/translate';
import useScreen from 'hooks/useScreen';

import { calculateBackTime } from './utils';

interface NotificationDrawerProps {
  onClose: () => void;
  anchorEl: null | HTMLElement;
}

const TABS = {
  ALL: 'all',
  UNREAD: 'unread',
};

const NotificationDrawer: FC<NotificationDrawerProps> = ({ onClose, anchorEl }) => {
  const [notificationsState, notificationsActions] = useNotifications();
  const [activeTab, setActiveTab] = useState(TABS.ALL);
  const navigate = useNavigate();
  const { isMobile } = useScreen();

  useMount(() => {
    notificationsActions.get();
  });

  const handleNotification = (notification: ICashierNotification): void => {
    notificationsActions.updateNotificationById(notification.id);
    onClose();
    switch (notification.actionId) {
      case CashierNotificationType.DEPOSIT_REQUEST:
        navigate('/payments/deposits/in-review');
        break;
      case CashierNotificationType.WITHDRAW_REQUEST:
        navigate('/payments/withdraws/in-review');
        break;
      default:
        break;
    }
  };

  const filteredNotifications = useMemo(() => {
    if (activeTab === TABS.UNREAD) {
      return notificationsState.data.filter((notification) => !notification.seenAt);
    }

    return notificationsState.data;
  }, [activeTab, notificationsState.data]);

  const unreadNotificationsExists = filteredNotifications.length > 0;

  return (
    <Menu
      id="notification-menu"
      anchorEl={anchorEl}
      sx={isMobile ? { '.MuiMenu-paper': { top: '0px !important', left: '0px !important' } } : {}}
      slotProps={{
        paper: {
          style: {
            height: isMobile ? '100%' : 500,
            width: isMobile ? '100%' : 400,
            ...(isMobile
              ? {
                  borderRadius: 0,
                  maxWidth: '100%',
                  maxHeight: 'calc(100% - 60px)',
                }
              : {}),
          },
        },
        root: {
          style: {
            width: isMobile ? '100%' : 400,
          },
        },
      }}
      open
      onClose={onClose}
    >
      <Tabs
        value={activeTab}
        sx={{
          '.MuiTabs-flexContainer': {
            justifyContent: isMobile ? 'center' : 'flex-start',
          },
          '.MuiTab-root': {
            flex: isMobile ? 1 : 'unset',
          },
        }}
        style={{ padding: isMobile ? 0 : '0px 26px' }}
        onChange={(_, value): void => {
          setActiveTab(value);
        }}
      >
        <Tab label={translate('All')} value={TABS.ALL}></Tab>
        <Tab label={translate('Unread')} value={TABS.UNREAD}></Tab>
      </Tabs>
      <Box display="flex" flexDirection="column" alignItems="center">
        {!unreadNotificationsExists && <MenuItem>No notifications</MenuItem>}
        {unreadNotificationsExists && (
          <>
            {filteredNotifications.map((notification) => {
              return (
                <MenuItem
                  style={{
                    gap: '8px',
                    width: '100%',
                    justifyContent: 'space-between',
                    opacity: notification.seenAt ? 0.6 : 1,
                  }}
                  key={notification.id}
                  onClick={(): void => handleNotification(notification)}
                >
                  <Box display="flex" alignItems="center" gap="8px">
                    <Box display="flex">
                      <AttachMoneyIcon color="primary" />
                    </Box>
                    <Box>
                      <Typography fontWeight="500">{notification.subject}</Typography>
                      <Typography color="GrayText" style={{ whiteSpace: 'normal' }}>
                        {notification.content}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignSelf="center"
                    flexDirection="column"
                    gap="8px"
                    justifyContent="space-between"
                  >
                    {!notification.seenAt && (
                      <Badge color="primary" variant="dot" style={{ alignSelf: 'end', marginRight: '4px' }} />
                    )}
                    <Typography display="flex" fontSize="12px" color="GrayText">
                      {calculateBackTime(notification.createdAt)}
                    </Typography>
                  </Box>
                </MenuItem>
              );
            })}
          </>
        )}
      </Box>
    </Menu>
  );
};

export default NotificationDrawer;
