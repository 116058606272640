import { ApiResponseType } from 'types';
import fetcher from 'utils/fetcher';
import { ICashierNotification } from 'types/notifications';

import * as API_URL from '../paths/constants';

export const getNotificationsRequest = (): Promise<ApiResponseType<ICashierNotification[]>> => {
  return fetcher({
    method: 'GET',
    url: API_URL.NOTIFICATIONS_GET,
  });
};

export const getUnreadNotificationsCountRequest = (): Promise<ApiResponseType<number>> => {
  return fetcher({
    method: 'GET',
    url: API_URL.NOTIFICATIONS_UNREAD_COUNT,
  });
};

export const updateNotificationById = (notificationId: number): Promise<ApiResponseType<ICashierNotification>> => {
  return fetcher({
    method: 'PUT',
    url: API_URL.UPDATE_NOTIFICATION(notificationId),
  });
};

export const updateNativeNotificationsToken = (token: string): Promise<ApiResponseType<void>> => {
  return fetcher<void, { firebaseToken: string }>({
    method: 'PUT',
    url: API_URL.NATIVE_NOTIFICATIONS_TOKEN,
    body: { firebaseToken: token },
  });
};

export const revokeNativeNotificationToken = (): Promise<ApiResponseType<void>> => {
  return fetcher<void, { firebaseToken: string }>({
    method: 'DELETE',
    url: API_URL.NATIVE_NOTIFICATIONS_TOKEN,
  });
};
