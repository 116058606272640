import { FC, useState, MouseEvent, Fragment } from 'react';
import { Badge, IconButton } from '@mui/material';

import NotificationIcon from 'components/common/icons/bulk/Notification';
import useMount from 'hooks/useMount';
import useNotifications from 'store/notifications';

import NotificationMenu from './NotificationMenu';

type Props = {
  footerAnchor?: null | HTMLElement;
};

const Notifications: FC<Props> = ({ footerAnchor }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationsState, notificationsActions] = useNotifications();

  const isNotificationOpen = Boolean(anchorEl);

  const handleNotificationClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(footerAnchor || event.currentTarget);
  };
  const handleNotificationClose = (): void => {
    setAnchorEl(null);
  };

  useMount(() => {
    notificationsActions.getUnreadCount();
  });

  return (
    <>
      <IconButton onClick={handleNotificationClick}>
        {notificationsState.unreadCount ? (
          <Badge
            color="error"
            badgeContent={notificationsState.unreadCount}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <NotificationIcon />
          </Badge>
        ) : (
          <NotificationIcon />
        )}
      </IconButton>
      {isNotificationOpen && <NotificationMenu anchorEl={anchorEl} onClose={handleNotificationClose} />}
    </>
  );
};

export default Notifications;
