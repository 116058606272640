import jsonParser from 'utils/parseJson';
import { WalletType } from 'types';

import { BrandingType, IUser } from './auth';

const jsonStringify = <T>(json: T): string => JSON.stringify(json);

export const authStorage = {
  set: (data: { cashier: IUser; accessToken: string; branding?: BrandingType }): void => {
    localStorage.setItem('cashier', jsonStringify<IUser>(data.cashier));
    localStorage.setItem('accessToken', jsonStringify<string>(data.accessToken));
    if (data?.branding) {
      localStorage.setItem('branding', jsonStringify<BrandingType>(data?.branding));
    }
  },
  get: (): { cashier: IUser; accessToken: string; wallets: WalletType[]; branding: BrandingType } => ({
    cashier: jsonParser(localStorage.getItem('cashier') || ''),
    accessToken: JSON.parse(localStorage.getItem('accessToken') || JSON.stringify('')),
    branding: jsonParser(localStorage.getItem('branding') || ''),
    wallets: [],
  }),
  remove: (): void => {
    localStorage.removeItem('cashier');
    localStorage.removeItem('accessToken');
  },
  clear: (): void => {
    localStorage.removeItem('cashier');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('branding');
    window.location.reload();
    window.location.pathname = '/';
  },
};
