import { LOGIN } from 'api/paths/constants';
import { AuthLoginResponseType, AuthLoginSchemaType } from 'store/auth';
import fetcher from 'utils/fetcher';

const login = async (body: AuthLoginSchemaType): Promise<AuthLoginResponseType> => {
  const response = await fetcher<AuthLoginResponseType, AuthLoginSchemaType>({
    url: LOGIN,
    method: 'POST',
    body,
  });

  return response.data;
};

export default login;
