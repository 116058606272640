const calculateBackTime = (time: number): string => {
  const now = Number(new Date());
  const diff = now - time;

  if (diff < 60000) {
    return 'Just now';
  }

  if (diff < 3600000) {
    return `${Math.floor(diff / 60000)}m ago`;
  }

  if (diff < 86400000) {
    return `${Math.floor(diff / 3600000)}h ago`;
  }

  return `${Math.floor(diff / 86400000)}d ago`;
};

export { calculateBackTime };
